export interface AppConfig {
  gwebHost: string;
  gwebApiKey: string;
  googleKey: string;
}


export async function loadConfig (): Promise<AppConfig> {
  const res = await fetch('/config.json');
  return await res.json();
}
