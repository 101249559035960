import { ResizeSensor } from 'css-element-queries';
import { format as dateFnsFormat } from 'date-fns';
import { Feature, View } from 'ol';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { MultiLineString, Point } from 'ol/geom';
import { SelectEvent } from 'ol/interaction/Select';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { all as allStrategy } from 'ol/loadingstrategy';
import Map from 'ol/Map';
import { Cluster, OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';

import { AppConfig, loadConfig } from './config';
import { aeIntendedPointStyle, aePointStyle, aePointStyleSelected, aeStyle, clusterStyleFn } from './feature-styles';
import { Geocoding } from './geocoding';
import { AE } from './interfaces';
import { getCenterOfLineStringCoords } from './utils';

class App {

  private map!: Map;
  private selectInteraction!: SelectCluster;
  private aeLayer!: VectorLayer<Feature>;
  private selectedAeLayer!: VectorLayer<Feature>;
  private searchInputElt!: HTMLInputElement;
  private searchListResultElt!: HTMLDivElement;

  private searchResults: { label: string; placeId: string }[] = [];
  private searchDebounce?: number;

  public currentSelectedFeature: Feature | null = null;

  private geocoding: Geocoding;

  public constructor (private appConfig: AppConfig) {
    this.geocoding = new Geocoding(appConfig);

    this.initMap();

    // Init buttons
    (document.getElementById('close-ae-panel-btn') as HTMLButtonElement).onclick = () => this.hideAe();
    (document.getElementById('open-global-panel-btn') as HTMLButtonElement).onclick = () => {
      const globalPanel = document.getElementById('global-panel');
      globalPanel?.classList.toggle('hide');
    };

    // Init search form
    document.getElementById('search-form')!.onsubmit = (ev) => this.onSearchSubmit(ev);
    this.searchListResultElt = document.getElementById('search-result-list')! as HTMLDivElement;
    this.searchInputElt = document.getElementById('search-input')! as HTMLInputElement;
    this.searchInputElt.oninput = () => {
      this.hideSearchResults();
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(async () => {
        await this.onSearchSubmit();
      }, 500);
    };
    this.searchInputElt.onfocus = () => {
      this.showSearchResults();
    };
    this.searchInputElt.onblur = () => {
      setTimeout(() => this.hideSearchResults(), 100);
    };

    // If on mobile, hide global pan by default
    if (document.body.clientWidth < 767) {
      const globalPanel = document.getElementById('global-panel');
      globalPanel?.classList.add('hide');
    }
  }

  private initMap () {
    const aeSource = new VectorSource({
      format: new GeoJSONFormat({ dataProjection: 'EPSG:3857', featureProjection: 'EPSG:3857' }),
      strategy: allStrategy,
      loader: (extent, _resolution, _projection, success, failure) => {
        fetch(`${this.appConfig.gwebHost}/-/api/app/ae/aes-public`, {
          headers: {
            'x-api-key': this.appConfig.gwebApiKey,
          },
        })
          .then((res) => res.json())
          .then((geoJSONData) => {
            const features = aeSource.getFormat()!.readFeatures(geoJSONData) as Feature<MultiLineString>[];
            const aePointsFeatures = features.map((aeFeature) => {
              aeFeature.setId(aeFeature.get('id'));
              const pointFeature = new Feature<Point>();
              pointFeature.setId(aeFeature.get('id'));
              pointFeature.setProperties({
                ...aeFeature.getProperties(),
                aeFeature,
              });
              pointFeature.setGeometry(
                aeSource.getFormat()!.readGeometry({
                  type: 'Point',
                  // Take the center of first lineString because it's the main segment
                  coordinates: getCenterOfLineStringCoords(
                    aeFeature.getGeometry()!.getLineString(0).getCoordinates(),
                  ),
                }) as Point,
              );
              pointFeature.setStyle(this.getAePointStyle(pointFeature));

              return pointFeature;
            });
            aeSource.addFeatures(aePointsFeatures);
            success?.(aePointsFeatures);
          })
          .catch((err) => {
            console.error('error fetching ae data', err);
            aeSource.removeLoadedExtent(extent);
            failure?.();
          });
      },
    });

    this.aeLayer = new AnimatedCluster({
      animationDuration: 500,
      source: new Cluster({
        source: aeSource,
      }),
      style: clusterStyleFn,

    });
    this.selectedAeLayer = new VectorLayer({
      source: new VectorSource(),
      style: aeStyle,
    });

    this.selectInteraction = new SelectCluster({
      pointRadius: 15,
      animate: false,
      featureStyle: aePointStyle,
      style: null,
      hitTolerance: 10,
    });
    this.selectInteraction.on('select', (event: SelectEvent) => {
      if (!event.selected.length) {
        this.hideAe();
      }
      else if (event.selected[0]!.get('features').length === 1) {
        this.showAe(event.selected[0]!.get('features')[0]!);
        this.selectInteraction.clear();
      }
    });

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.selectedAeLayer,
        this.aeLayer,
      ],
      view: new View({
        center: [ 538135.788385, 5741682.035183 ],
        zoom: 13,
      }),
    });
    new ResizeSensor(this.map.getTargetElement(), () => {
      this.map.updateSize();
    });
    this.map.addInteraction(this.selectInteraction);
  }

  public showAe (aeFeat: Feature) {
    this.currentSelectedFeature?.setStyle(this.getAePointStyle(this.currentSelectedFeature));
    this.currentSelectedFeature = aeFeat;
    this.currentSelectedFeature.setStyle(aePointStyleSelected);
    this.selectedAeLayer.getSource()!.clear();
    this.selectedAeLayer.getSource()!.addFeature(aeFeat.get('aeFeature'));

    const ae  = aeFeat.getProperties() as AE;
    // console.log('ae', ae);
    document.getElementById('ae-title')!.innerText = `Arrêt d'eau n°${ae.id}`;
    document.getElementById('ae-description')!.innerText = ae.address;
    document.getElementById('ae-start-date')!.innerText = dateFnsFormat(new Date(ae.estimatedStartDate), 'dd/MM/yyyy');
    document.getElementById('ae-end-date')!.innerText = dateFnsFormat(new Date(ae.estimatedEndDate), 'dd/MM/yyyy');

    if (ae.meta.impactedStreets?.length) {
      document.getElementById('ae-impacted-streets')!.innerHTML = ae.meta.impactedStreets.map((street) => {
        const evenNum = (street.evenNumber.start && street.evenNumber.end) ? `du ${street.evenNumber.start} au ${street.evenNumber.end} côté pair`
          : street.evenNumber.start ? `depuis le ${street.evenNumber.start} côté pair`
          : street.evenNumber.end ? `jusqu'au ${street.evenNumber.end} côté pair`
          : undefined;
        const oddNum = (street.oddNumber.start && street.oddNumber.end) ? `du ${street.oddNumber.start} au ${street.oddNumber.end} côté pair`
          : street.oddNumber.start ? `depuis le ${street.oddNumber.start} côté impair`
          : street.oddNumber.end ? `jusqu'au ${street.oddNumber.end} côté impair`
          : undefined;
        const num = evenNum && oddNum ? [ evenNum, oddNum ].join(' et ') : evenNum ?? oddNum;
        return `<li class="street">${street.street} ${street.city} ${num ? `<div class="num">(${num})</div>` : ''}</li>`;
      }).join('\n');
    }
    else {
      document.getElementById('ae-impacted-streets')!.innerHTML = '<div class="no-data">Non précisées</div>';
    }

    const panel = document.getElementById('ae-panel')!;
    panel.classList.add('opened');
  }

  public hideAe () {
    this.currentSelectedFeature?.setStyle(this.getAePointStyle(this.currentSelectedFeature));
    this.currentSelectedFeature = null;
    this.selectedAeLayer.getSource()!.clear();
    const panel = document.getElementById('ae-panel')!;
    panel.classList.remove('opened');
    this.selectInteraction.getFeatures().clear();
  }

  public async onSearchSubmit (ev?: SubmitEvent) {
    ev?.preventDefault();

    if (this.searchInputElt.value) {
      const currentExtent = this.map.getView().getViewStateAndExtent().extent;
      this.searchResults = await this.geocoding.geocodeAddress(this.searchInputElt.value, currentExtent);
    }
    else {
      this.searchResults = [];
    }

    console.log('results', this.searchResults);
    this.showSearchResults();
  }

  public showSearchResults () {
    this.searchListResultElt.innerHTML = ''; // Clean list
    for (const result of this.searchResults) {
      const elt = document.createElement('div');
      elt.innerHTML = result.label;
      elt.classList.add('search-result-item');
      elt.onclick = () => this.goToPlace(result.placeId);
      this.searchListResultElt.appendChild(elt);
    }
  }

  public hideSearchResults () {
    this.searchListResultElt.innerHTML = ''; // Clean list
  }

  public async goToPlace (placeId: string) {
    const location = await this.geocoding.getPlaceLocation(placeId);
    this.map.getView().fit(location.preferredExtent as any);
    this.hideSearchResults();
  }

  private getAePointStyle (feat: Feature | undefined) {
    if (!feat) return undefined;
    if (feat.getProperties().meta.intended) {
      return aeIntendedPointStyle;
    }
    else {
      return aePointStyle;
    }
  }
}

void loadConfig()
  .then((appConfig) => {
    new App(appConfig);
  })
  .catch((err) => {
    console.error('Error loading config', err);
  });
