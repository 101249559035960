import { Feature } from 'ol';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

export const  aeStyle = new Style({
  stroke: new Stroke({
    width: 5,
    color: [ 255, 51, 51 ],
  }),
});
export const aePointStyle = new Style({
  image: new CircleStyle({
    radius: 7,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#3399CC',
    }),
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 1,
  }),
});
export const aeIntendedPointStyle = new Style({
  image: new CircleStyle({
    radius: 7,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#7DCC33',
    }),
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 1,
  }),
});

export const aePointStyleSelected = new Style({
  image: new CircleStyle({
    radius: 7,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#cc3333',
    }),
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 1,
  }),
});

const styleCache: Record<number, Style | undefined> = {};
export function clusterStyleFn (feature: Feature) {
  const size = feature.get('features').length;
  if (size === 1) {
    return feature.get('features')[0]!.getStyle();
  }

  let style = styleCache[size];
  if (!style) {
    style = new Style({
      image: new CircleStyle({
        radius: 10,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#3399CC',
        }),
      }),
      text: new Text({
        text: size.toString(),
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
    styleCache[size] = style;
  }
  return style;
}
