import { Coordinate } from 'ol/coordinate';


export function getCenterOfLineStringCoords (coordinates: Coordinate[]): Coordinate {
  const centerIdx = Math.floor(coordinates.length / 2);
  if (coordinates.length % 2 === 0) {
    return [
      (coordinates[centerIdx - 1]![0]! + coordinates[centerIdx]![0]!) / 2,
      (coordinates[centerIdx - 1]![1]! + coordinates[centerIdx]![1]!) / 2,
    ];
  }
  else {
    return coordinates[centerIdx];
  }
}
