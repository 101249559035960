
import { Extent } from 'ol/extent';
import * as olProj from 'ol/proj';

import { AppConfig } from './config';


export class Geocoding {

  public constructor (private appConfig: AppConfig) {}

  public async geocodeAddress (addressSearch: string, bboxBias?: Extent) {
    await this.ensureScriptLoaded();

    let locationBias: google.maps.LatLngBoundsLiteral | undefined;
    if (bboxBias) {
      const bottomLeft = olProj.toLonLat([ bboxBias[0]!, bboxBias[1]! ]);
      const topRight = olProj.toLonLat([ bboxBias[2]!, bboxBias[3]! ]);
      locationBias = {
        east: topRight[0]!,
        north: topRight[1]!,
        south: bottomLeft[1]!,
        west: bottomLeft[0]!,
      };
    }

    const autoCompleteService = new google.maps.places.AutocompleteService();
    const res = await autoCompleteService.getPlacePredictions({
      input: addressSearch,
      language: 'fr',
      locationBias,
    });
    return res.predictions.map((prediction) => ({
      placeId: prediction.place_id,
      label: prediction.description,
    }));
  }

  public async getPlaceLocation (placeId: string) {
    await this.ensureScriptLoaded();

    const placeService = new google.maps.places.PlacesService(document.createElement('div'));

    const res = await new Promise<google.maps.places.PlaceResult>((resolve) => {
      placeService.getDetails({
        placeId,
        fields: [ 'geometry.location', 'geometry.viewport' ],
      }, (r) => resolve(r as google.maps.places.PlaceResult));
    });

    const topRight = olProj.fromLonLat([ res.geometry!.viewport!.getNorthEast().lng(), res.geometry!.viewport!.getNorthEast().lat() ]);
    const bottomLeft = olProj.fromLonLat([ res.geometry!.viewport!.getSouthWest().lng(), res.geometry!.viewport!.getSouthWest().lat() ]);
    return {
      location: olProj.fromLonLat([ res.geometry!.location!.lng(), res.geometry!.location!.lat() ]),
      preferredExtent: [ bottomLeft[0], bottomLeft[1], topRight[0], topRight[1] ],
    };
  }

  private async ensureScriptLoaded () {
    if (!(window as any).google) {
      await new Promise<void>((resolve) => {
        (window as any).googlePlaceLibLoaded = () => {
          resolve();
          delete (window as any).googlePlaceLibLoaded;
        };
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.appConfig.googleKey}&loading=async&libraries=places&callback=googlePlaceLibLoaded`;
        document.body.appendChild(script);
      });
    }
  }
}
